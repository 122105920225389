import { useNotificationContext } from '../notifications/NotificationContext'
import { intlDateFormatter } from '../utils/dateFormatter'
import { NOTIF_TAB } from '../constants'
import PulseLoading from './PulseLoading'
import { useNavigate } from 'react-router-dom'
import useInfiniteScroll from '../hooks/useInfiniteScroll'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

function NotificationDropdown({ toggleDropdown }) {
  const {
    notifications,
    isFetchingNotifications,
    switchNotificationTab,
    tabStatus,
    fetchMoreNotifications,
    hasMore,
    completeNotificationPrimaryAction,
    markAllNotificationsAsRead,
  } = useNotificationContext()
  const navigate = useNavigate()

  const observerRef = useInfiniteScroll({
    fetchMore: fetchMoreNotifications,
    hasMore,
    isFetching: isFetchingNotifications,
  })

  useEffect(() => {
    return () => {
      markAllNotificationsAsRead()
    } // mark alll notifications as read when unmounting
  }, [])

  const handleNotificationClick = ({ id, url }) => {
    completeNotificationPrimaryAction({ id })

    if (url) {
      const { pathname: nextPath } = new URL(url)
      toggleDropdown()

      navigate(nextPath)
    }
  }

  return (
    <div className='notificationDropdown'>
      {/* tabs  ====================================== */}
      <div className='tabs'>
        <button
          className='unread'
          style={{
            backgroundColor:
              tabStatus === NOTIF_TAB.UNREAD_ONLY ? '' : '#1d1c43',
          }}
          onClick={() => {
            switchNotificationTab(NOTIF_TAB.UNREAD_ONLY)
          }}
        >
          Unread
        </button>
        <button
          className='all'
          style={{
            backgroundColor: tabStatus === NOTIF_TAB.ALL ? '' : '#1d1c43',
          }}
          onClick={() => switchNotificationTab(NOTIF_TAB.ALL)}
        >
          All
        </button>
      </div>
      {isFetchingNotifications && notifications.length === 0 && (
        <div className='u-mt-2'>
          <PulseLoading />
        </div>
      )}

      {!isFetchingNotifications && notifications.length === 0 && (
        <div className='noNotification'>
          <h4>No new notification</h4>
        </div>
      )}
      {notifications?.length > 0 && (
        <div className='notificationContainer'>
          {notifications.map(
            ({ id, subject, body, createdAt, primaryAction }, index) => {
              const formattedDate = intlDateFormatter({
                date: createdAt,
                formatStandard: 'en-GB',
                formatOptions: {
                  year: '2-digit',
                  month: 'short',
                  day: 'numeric',
                },
              })
              return (
                <div
                  className='notificationBox'
                  key={id}
                  ref={index === notifications.length - 1 ? observerRef : null}
                  onClick={() =>
                    handleNotificationClick({
                      id: id,
                      url: primaryAction?.redirect?.url,
                    })
                  }
                >
                  <p className='date'>{formattedDate}</p>
                  <h5>{subject}</h5>
                  <p className='body'>{body}</p>
                </div>
              )
            }
          )}
          {isFetchingNotifications && <PulseLoading />}
          {!hasMore && (
            <div className='u-flex-center u-mt-2 u-ft-x-small'>
              <h4>No more notifications</h4>
            </div>
          )}
        </div>
      )}
      <div className='pageContainer'></div>
    </div>
  )
}

export default NotificationDropdown

NotificationDropdown.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
}
