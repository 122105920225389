import useDropdown from '../hooks/useDropdown'
import { useNotificationContext } from '../notifications/NotificationContext'
import NotificationDropdown from './NotificationDropdown'

function Notification() {
  const { unreadCount } = useNotificationContext()
  const { isOpen, toggleDropdown, ref } = useDropdown()

  const notificationIconText = unreadCount >= 99 ? '99+' : unreadCount
  return (
    <div className='notificationSection' ref={ref}>
      <div className='iconContainer' onClick={toggleDropdown}>
        <i className='bi bi-bell-fill'></i>
      </div>
      {unreadCount > 0 && (
        <span className='budget'>{notificationIconText}</span>
      )}
      {isOpen && <NotificationDropdown toggleDropdown={toggleDropdown} />}
    </div>
  )
}

export default Notification
